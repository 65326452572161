/**
 * Initialize the functions
 */
initMobileMenu();
initSlickPartners();
initSlickSliderFull();
initBackToTop();
initSlickSliderProduct();

/**
 * TOOGLE CLASS MOBILE MENU
 */
function initMobileMenu() {
    // Exit when component don't exist
    if (!$(".mobile-menu").length) {
        return;
    }
    $(".header").on("click", ".mobile-menu", (evt) => {
        const elemCurrent = $(evt.currentTarget);

        elemCurrent.toggleClass("open");
        $("body").toggleClass("open-menu-mobile body-no-scroll");
    });
}

/**
 *
 */

function initSlickPartners() {
    // Exit when component don't exist
    if (!$(".partners-content").length) {
        return;
    }
    $(".partners-content").slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

function initSlickSliderFull() {
    // Exit when component don't exist
    if (!$(".slider-full").length) {
        return;
    }
    $(".slider-full").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    });
}

function initSlickSliderProduct() {
    // Exit when component don't exist
    if (!$(".slider-product-content").length) {
        return;
    }
    $(".slider-product-content").slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

function initBackToTop() {
    //Switch dark/light
    $(".switch").on('click', function () {
        if ($("body").hasClass("light")) {
            $("body").removeClass("light");
            $(".switch").removeClass("switched");
        }
        else {
            $("body").addClass("light");
            $(".switch").addClass("switched");
        }
    });

    $(document).ready(function(){"use strict";
        //Scroll back to top
        var progressPath = document.querySelector(".progress-wrap path");
        var pathLength = progressPath.getTotalLength();
        progressPath.style.transition = progressPath.style.WebkitTransition = "none";
        progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
        progressPath.style.strokeDashoffset = pathLength;
        progressPath.getBoundingClientRect();
        progressPath.style.transition = progressPath.style.WebkitTransition = "stroke-dashoffset 10ms linear";
        var updateProgress = function () {
            var scroll = $(window).scrollTop();
            var height = $(document).height() - $(window).height();
            var progress = pathLength - (scroll * pathLength / height);
            progressPath.style.strokeDashoffset = progress;
        }
        updateProgress();
        $(window).scroll(updateProgress);
        var offset = 50;
        var duration = 550;
        $(window).on("scroll", function() {
            if ($(this).scrollTop() > offset) {
                $(".progress-wrap").addClass("active-progress");
            } else {
                $(".progress-wrap").removeClass("active-progress");
            }
        });
        $(".progress-wrap").on("click", function(event) {
            event.preventDefault();
            $("html, body").animate({scrollTop: 0}, duration);
            return false;
        })
    });
}

$(window).on("scroll", function() {
    if ($(window).scrollTop() > 1) {
        $(".header").addClass("is-sticky");
    } else {
        $(".header").removeClass("is-sticky");
    }
});